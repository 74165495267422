import React from "react";

import { graphql } from "gatsby";

import { Seo, TagsContainer } from "../components";
import Layout from "../components/Layout";

const ArtistsPage = ({ data }) => {
  const artists = data.allArtists.nodes;
  const allArtists = artists.map(artist => artist.data.artist);
  const mergedArtists = [].concat.apply([], allArtists);
  const uniqueArtists = [...new Set(mergedArtists)];

  const compareStrings = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const compare = (a, b) => {
    const splitA = a.split(" ");
    const splitB = b.split(" ");
    const lastA = splitA[splitA.length - 1];
    const lastB = splitB[splitB.length - 1];

    return lastA === lastB
      ? compareStrings(splitA[0], splitB[0])
      : compareStrings(lastA, lastB);
  };

  const sortedArtists = uniqueArtists.sort(compare);

  return (
    <Layout>
      <Seo title="Artists" />
      <TagsContainer title="Artists" tags={sortedArtists} isArtist />
    </Layout>
  );
};

export const query = graphql`
  {
    allArtists: allAirtable(
      filter: {
        table: { eq: "catalogue" }
        data: { status: { eq: "Available" } }
      }
    ) {
      nodes {
        data {
          artist
        }
      }
    }
  }
`;

export default ArtistsPage;
